export const TAG_COLORS = [
    "#8e44ad", "#2980b9", "#27ae60", "#f39c12", "#c0392b", 
    "#2c3e50", "#d35400", "#16a085", "#7f8c8d"
];
  
export function hashTagToColor(tag: string) {
    let hash = 0;
    for (let i = 0; i < tag.length; i++) {
        hash = (hash << 5) - hash + tag.charCodeAt(i);
        hash |= 0;
    }
    const index = Math.abs(hash) % TAG_COLORS.length;
    return TAG_COLORS[index];
}