import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RootState, AppDispatch } from "../../../redux/store";
import { RolodexPerson } from "../../../redux/reducers/rolodexReducer";
import {
  publishRolodexPerson,
  updateRolodexPerson,
} from "../../../redux/actions/rolodexActions";
import { fetchConversations } from "../../../redux/actions/conversationsActions";
import { format } from "date-fns";

const formatDate = (dateString: string | undefined) => {
  if (!dateString) return "N/A"; // Handle empty or undefined dates
  const date = new Date(dateString);
  return format(date, "MMMM d, yyyy h:mm aaaa 'PST'");
};

const RolodexView: React.FC = () => {
  const { personId } = useParams<{ personId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // Redux slices
  const {
    rolodex,
    loading: rolodexLoading,
    error: rolodexError,
  } = useSelector((state: RootState) => state.rolodex);
  const { conversations, loading: convoLoading } = useSelector(
    (state: RootState) => state.conversations
  );

  // The current person, found by ID
  const person = rolodex.find((p: RolodexPerson) => p.id === personId);

  // UI state
  const [editMode, setEditMode] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [publishDialogOpen, setPublishDialogOpen] = useState(false);

  // Fields for editing
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [summary, setSummary] = useState("");
  const [selectedConversations, setSelectedConversations] = useState<string[]>(
    []
  );

  // If our Rolodex is empty, but we have a personId, navigate to list w/param
  useEffect(() => {
    if (rolodex.length === 0) {
      if (personId) {
        navigate(`/dashboard/rolodex?rolodex_id=${personId}`);
      } else {
        navigate(`/dashboard/rolodex`);
      }
    }
  }, [rolodex, personId, navigate]);

  // When the `person` changes, initialize local states
  useEffect(() => {
    if (person) {
      const f = person.data?.name.first_name || "";
      const m = person.data?.name.middle_name || "";
      const l = person.data?.name.last_name || "";
      setFirstName(f);
      setMiddleName(m);
      setLastName(l);
      setSummary(person.data.summary || "");
      setSelectedConversations([...person.conversation_ids]);
    }
  }, [person]);

  // Toggle edit mode
  const handleToggleEdit = () => {
    if (!editMode) {
      // We are about to enter edit mode; fetch conversations if they're empty
      if (conversations.length === 0) {
        dispatch(fetchConversations());
      }
    }
    setEditMode((prev) => !prev);
  };

  // Save changes confirm
  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  // Publish confirm
  const handleOpenPublishDialog = () => {
    setPublishDialogOpen(true);
  };
  const handleClosePublishDialog = () => {
    setPublishDialogOpen(false);
  };

  const handleConfirmPublish = () => {
    if (!personId) return;
    setPublishDialogOpen(false);
    dispatch(publishRolodexPerson({ id: personId }))
      .unwrap()
      .then(() => {
        toast.success("Rolodex person published successfully!");
      })
      .catch(() => {
        toast.error("Failed to publish rolodex person.");
      });
  };

  // Save changes
  const handleSaveChanges = () => {
    if (!personId || !person) return;
    setConfirmDialogOpen(false);

    // Build the updates
    const updates: Partial<RolodexPerson> = {
      conversation_ids: [...selectedConversations],
      data: {
        ...person.data,
        name: {
          ...person.data.name,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
        },
        summary,
      },
    };

    dispatch(updateRolodexPerson({ id: personId, updates }))
      .unwrap()
      .then(() => {
        toast.success("Rolodex person updated successfully!");
        setEditMode(false);
      })
      .catch(() => {
        toast.error("Failed to update rolodex person.");
      });
  };

  // If still loading or there's an error
  if (rolodexLoading) return <CircularProgress />;
  if (rolodexError) return <div>Error: {rolodexError}</div>;
  if (!person) return <div>No Rolodex person found with ID: {personId}</div>;

  // Also check if conversation fetch is in progress (only relevant if edit mode)
  const showConversationLoader =
    editMode && convoLoading && conversations.length === 0;

  // Determine background based on editMode:
  //   - If editMode => transparent
  //   - If read-only => "rgba(0,0,0,0.05)"
  const getBackgroundColor = (edit: boolean) =>
    edit ? "transparent" : "rgba(0,0,0,0.05)";

  // Check if publish button should be disabled
  const publishDisabled = person.version === person.published_version;

  // Published conversation IDs
  const publishedConvIds = person?.published_conversation_ids || [];

  // Called whenever the user changes the set of conversation IDs
  const handleConversationsChange = (_: any, newValue: string[]) => {
    // Force published IDs to remain in the list
    const finalValue = [
      ...publishedConvIds,
      ...newValue.filter((id) => !publishedConvIds.includes(id)),
    ];
    setSelectedConversations(finalValue);
  };

  return (
    <Box sx={{ p: 4 }}>
      <ToastContainer position="top-center" autoClose={3000} />
      <Typography variant="h5" gutterBottom>
        Rolodex Person Details
      </Typography>

      {/* ID (always read-only) */}
      <TextField
        label="Rolodex ID"
        value={person.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      {/* Version (read-only) */}
      <TextField
        label="Version"
        value={person.version || ""}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      {/* Published Version (read-only) */}
      <TextField
        label="Published Version"
        value={person.published_version || ""}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      {/* First Name */}
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e) => editMode && setFirstName(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Middle Name */}
      <TextField
        label="Middle Name"
        value={middleName}
        onChange={(e) => editMode && setMiddleName(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Last Name */}
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(e) => editMode && setLastName(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Created At (read-only) */}
      <TextField
        label="Created At"
        value={formatDate(person.created_at)}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      {/* Updated At (read-only) */}
      <TextField
        label="Updated At"
        value={person?.updated_at ? formatDate(person.updated_at) : "N/A"}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
        sx={{ backgroundColor: getBackgroundColor(false) }}
      />

      {/* Conversation IDs */}
      {editMode ? (
        showConversationLoader ? (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Loading Conversations...
            </Typography>
          </Box>
        ) : (
          <Autocomplete
            multiple
            options={conversations.map((c) => c.id)}
            value={selectedConversations}
            onChange={handleConversationsChange}
            // Render tags to color published vs. unpublished
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const isPublished = publishedConvIds.includes(option);
                return (
                  <Chip
                    label={option}
                    // If published, disable removal
                    key={option}
                    onDelete={
                      isPublished ? undefined : getTagProps({ index }).onDelete
                    }
                    // Use a different color or style for published vs. non-published
                    sx={{
                      mr: 0.5,
                      mb: 0.5,
                      bgcolor: isPublished ? "#c8e6c9" : "#bbdefb", // Example: green vs. blue
                    }}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Conversation IDs"
                variant="outlined"
                margin="normal"
                placeholder="Select conversation IDs"
                sx={{ backgroundColor: getBackgroundColor(true) }}
              />
            )}
            sx={{ mt: 2 }}
          />
        )
      ) : (
        <TextField
          label="Conversation IDs"
          value={person.conversation_ids.join(", ")}
          fullWidth
          margin="normal"
          multiline
          minRows={2}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          sx={{ backgroundColor: getBackgroundColor(false) }}
        />
      )}

      {/* Summary */}
      <TextField
        label="Summary"
        fullWidth
        margin="normal"
        multiline
        minRows={3}
        value={summary}
        onChange={(e) => editMode && setSummary(e.target.value)}
        sx={{ backgroundColor: getBackgroundColor(editMode) }}
        InputProps={{ readOnly: !editMode }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Buttons */}
      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
        {!editMode ? (
          <Button variant="contained" onClick={handleToggleEdit}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={handleOpenConfirmDialog}
              disabled={convoLoading}
            >
              {convoLoading ? "Save Changes" : "Loading Conversations..."}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                // Revert changes
                const f = person.data?.name.first_name || "";
                const m = person.data?.name.middle_name || "";
                const l = person.data?.name.last_name || "";
                setFirstName(f);
                setMiddleName(m);
                setLastName(l);
                setSummary(person.data.summary || "");
                setSelectedConversations([...person.conversation_ids]);
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
          </>
        )}

        <Button
          variant="contained"
          color="success"
          onClick={handleOpenPublishDialog}
          disabled={publishDisabled || editMode}
        >
          {publishDisabled ? "Latest Version Published" : "Publish"}
        </Button>

        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/rolodex")}
        >
          Back
        </Button>
      </Box>

      {/* Confirm Save Dialog */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Update Rolodex Person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleSaveChanges} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Publish Dialog */}
      <Dialog open={publishDialogOpen} onClose={handleClosePublishDialog}>
        <DialogTitle>Publish Rolodex Person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Publishing will create SourcedInformation for each conversation and
            then set your published_version to match the current version. All
            current conversation ids will be published and can no longer be
            removed from this rolodex entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePublishDialog}>Cancel</Button>
          <Button onClick={handleConfirmPublish} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RolodexView;
