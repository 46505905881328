// SearchBar.tsx
import React from "react";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import {
  Close as CloseIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  searchResults: number[];
  currentSearchIndex: number;
  handleNextResult: () => void;
  handlePrevResult: () => void;
  setShowSearchBar: (val: boolean) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  searchResults,
  currentSearchIndex,
  handleNextResult,
  handlePrevResult,
  setShowSearchBar,
  searchInputRef,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 30,
        right: 10,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "8px",
        boxShadow: 1,
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search..."
        value={searchTerm}
        inputRef={searchInputRef}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setShowSearchBar(false);
            setSearchTerm("");
          }
          if (e.key === "Enter") {
            handleNextResult();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              onClick={() => {
                setShowSearchBar(false);
                setSearchTerm("");
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
      {searchResults.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
          <IconButton size="small" onClick={handlePrevResult}>
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton size="small" onClick={handleNextResult}>
            <ArrowDownwardIcon />
          </IconButton>
          <Typography variant="caption" sx={{ ml: 1 }}>
            {currentSearchIndex + 1}/{searchResults.length}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
