// Enumerations for annotation categories
export enum ChatAnnotationCategory {
    Unknown = 0,
    Reaction = 1,
    Feedback = 2,
    Violation = 3,
  }
  
  // Enumerations for reaction annotations
  export enum ChatAnnotationReaction {
    Unknown = 0,
    Love = 1,
    Like = 2,
    Cry = 3,
    Think = 4,
    Lol = 5,
    Question = 6,
    Rage = 7,
  }
  
  // Enumerations for feedback annotations
  export enum ChatAnnotationFeedback {
    Unknown = 0,
    ThumbsUp = 1,
    ThumbsDown = 2,
  }
  
  // Enumerations for message modality
  export enum ChatMessageModality {
    Text = "text",
    Audio = "audio",
    Video = "video",
    AudioVideo = "audio_video",
  }
  
  // Interface for a chat annotation
  export interface ChatAnnotation {
    category: ChatAnnotationCategory;
    content: ChatAnnotationReaction | ChatAnnotationFeedback;
    chat_speaker_id: string;
    action: any | null;
    speaker_num: number | null;
    speaker_type: any | null;
    timestamp: string;
  }
  