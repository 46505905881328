import React from "react";
import { Box, IconButton, Badge, Tooltip } from "@mui/material";
import {
  AddComment as AddCommentIcon,
  Share as ShareIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  Message,
  Comment,
} from "../../../../redux/reducers/conversationsReducer";

interface MessageActionsProps {
  message: Message;
  comments: Comment[];
  showComments: boolean;
  setShowComments: (val: boolean) => void;
  canViewModelCalls?: boolean;
  handleShowLogsModal: () => void;
  handleShareMessage: () => void;
  showShareTooltip: boolean;
  setShowCommentModal: (val: boolean) => void;
  canViewAllData?: boolean;
  isRightAligned: boolean;
  conversationId?: string;
  chatSessionId: string;
  canViewMessageAudio?: boolean;
}

const MessageActions: React.FC<MessageActionsProps> = ({
  message,
  comments,
  showComments,
  setShowComments,
  canViewModelCalls,
  handleShowLogsModal,
  handleShareMessage,
  showShareTooltip,
  setShowCommentModal,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        mt: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={() => setShowCommentModal(true)}
        aria-label="Add Comment"
      >
        <AddCommentIcon style={{ color: "black" }} />
      </IconButton>
      {canViewModelCalls && (
        <IconButton
          size="small"
          onClick={handleShowLogsModal}
          aria-label="Show Logs"
        >
          <InfoIcon style={{ color: "black" }} />
        </IconButton>
      )}
      {comments.length > 0 && (
        <IconButton
          size="small"
          onClick={() => setShowComments(!showComments)}
          aria-label="Toggle Comments"
        >
          <Badge badgeContent={comments.length} color="info" overlap="circular">
            <QuestionAnswerIcon style={{ color: "black" }} />
          </Badge>
        </IconButton>
      )}
      <Tooltip
        title={showShareTooltip ? "Copied to clipboard" : "Share message"}
        open={showShareTooltip}
      >
        <IconButton size="small" onClick={handleShareMessage}>
          <ShareIcon style={{ color: "black" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default MessageActions;
