import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStytchUser } from "@stytch/react";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Autocomplete } from "@mui/material";

import { RootState, AppDispatch } from "../../redux/store";
import {
  fetchRolodex,
  createRolodexPerson,
} from "../../redux/actions/rolodexActions";
import { RolodexPerson } from "../../redux/reducers/rolodexReducer";
import { fetchConversations } from "../../redux/actions/conversationsActions";

const RolodexTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useStytchUser();

  const { rolodex, loading, error } = useSelector(
    (state: RootState) => state.rolodex
  );

  const { loading: conversationLoading } = useSelector(
    (state: RootState) => state.conversations
  );
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams(); // ADD THIS

  const removeRolodexParams = useCallback(() => {
    if (searchParams.has("rolodex_id")) {
      searchParams.delete("rolodex_id");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const { conversations } = useSelector(
    (state: RootState) => state.conversations
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [selectedConversations, setSelectedConversations] = useState<string[]>(
    []
  );
  const [newFirstName, setNewFirstName] = useState("");
  const [newMiddleName, setNewMiddleName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newSummary, setNewSummary] = useState("");

  const debouncedFetchRolodex = useMemo(
    () => debounce(() => dispatch(fetchRolodex()), 300),
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      debouncedFetchRolodex();
      // Also fetch all conversations for the Autocomplete if not loaded
      dispatch(fetchConversations());
    }
    return () => {
      debouncedFetchRolodex.cancel();
    };
  }, [user, debouncedFetchRolodex, dispatch]);

  const filteredData = useMemo(() => {
    if (!searchQuery) return rolodex;
    const fuse = new Fuse(rolodex, {
      keys: [
        "id",
        "data.name.first_name",
        "data.name.last_name",
        "data.summary",
      ],
      threshold: 0.3,
    });
    return fuse.search(searchQuery).map((res) => res.item);
  }, [rolodex, searchQuery]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rolodexId = queryParams.get("rolodex_id");

    if (rolodexId && filteredData.length > 0) {
      removeRolodexParams();
      navigate(`/dashboard/rolodex/${rolodexId}`);
    }
  }, [filteredData, location.search, navigate, removeRolodexParams]);

  const handleView = useCallback((id: string) => {
    window.open(`/dashboard/rolodex/${id}`, "_blank");
  }, []);

  console.log(filteredData);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 80,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => {
          const fn = row.data?.name?.first_name || "";
          const mn = row.data?.name?.middle_name || "";
          const ln = row.data?.name?.last_name || "";
          return [fn, mn, ln].filter(Boolean).join(" ");
        },
      },
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        minWidth: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "summary",
        headerName: "Summary",
        flex: 1,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => row.data?.summary || "",
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 1,
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        valueGetter: (params: any, row: any) => {
          const date = new Date(row.created_at);
          return date.toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          });
        },
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        flex: 1,
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        valueGetter: (params: any, row: any) => {
          if (row.updated_at === null) return "";
          const date = new Date(row.updated_at);
          return date.toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          });
        },
      },
      {
        field: "version",
        headerName: "Version",
        flex: 1,
        minWidth: 30,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => row.version || "",
      },
      {
        field: "published_version",
        headerName: "Pub Version",
        flex: 1,
        minWidth: 30,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => row.published_version || "",
      },
      {
        field: "published_conversations",
        headerName: "Pub Conversations",
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any, row: any) => {
          const publishedIds = row.published_conversation_ids || [];
          return publishedIds.join(", ");
        },
      },
      {
        field: "actions",
        headerName: "",
        flex: 1,
        minWidth: 50,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const row = params.row as RolodexPerson;
          return (
            <Button variant="contained" onClick={() => handleView(row.id)}>
              View
            </Button>
          );
        },
      },
    ],
    [handleView]
  );
  const debouncedFetchConversations = useMemo(
    () => debounce(() => dispatch(fetchConversations()), 300),
    [dispatch]
  );

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
    debouncedFetchConversations();
  };
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setSelectedConversations([]);
    setNewFirstName("");
    setNewMiddleName("");
    setNewLastName("");
    setNewSummary("");
  };

  const handleCreatePerson = () => {
    dispatch(
      createRolodexPerson({
        conversation_ids: selectedConversations,
        data: {
          name: {
            first_name: newFirstName,
            middle_name: newMiddleName,
            last_name: newLastName,
          },
          summary: newSummary,
        },
      })
    )
      .unwrap()
      .then((newPerson) => {
        handleCloseCreateDialog();
        navigate(`/dashboard/rolodex/${newPerson.id}`);
      })
      .catch((err) => {
        console.error("Failed to create new rolodex person", err);
      });
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Search Rolodex"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ flex: 1 }}
        />
        <Button variant="outlined" onClick={() => setSearchQuery("")}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleOpenCreateDialog}>
          Create New Person
        </Button>
      </Box>

      <Box sx={{ height: "70vh", width: "100%" }}>
        <DataGrid rows={filteredData} columns={columns} />
      </Box>

      <Dialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Create Rolodex Person</DialogTitle>
        <DialogContent>
          {conversationLoading ? (
            <>
              Loading Conversations... <CircularProgress size="1rem" />
            </>
          ) : (
            <>
              <DialogContentText>
                Enter details for a new Rolodex person.
              </DialogContentText>
              <TextField
                margin="dense"
                label="First Name"
                fullWidth
                variant="outlined"
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                margin="dense"
                label="Middle Name"
                fullWidth
                variant="outlined"
                value={newMiddleName}
                onChange={(e) => setNewMiddleName(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                variant="outlined"
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                margin="dense"
                label="Summary"
                fullWidth
                variant="outlined"
                value={newSummary}
                onChange={(e) => setNewSummary(e.target.value)}
                multiline
                minRows={2}
                sx={{ mt: 2 }}
              />
              <Autocomplete
                multiple
                options={conversations.map((c) => c.id)} // or conversation objects if you'd rather
                value={selectedConversations}
                onChange={(_, newValue) => setSelectedConversations(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Conversations"
                    placeholder="Select conversation IDs"
                    sx={{ mt: 2 }}
                  />
                )}
                sx={{ mt: 2 }}
              />{" "}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleCreatePerson}
            disabled={
              conversationLoading ||
              (!newFirstName && !newLastName && !newMiddleName) ||
              selectedConversations.length === 0
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RolodexTab;
