import React from "react";
import { Box, Collapse } from "@mui/material";
import ThreadComponent from "../ThreadComponent";
import { Comment } from "../../../../redux/reducers/conversationsReducer";

interface CommentsSectionProps {
  threads: Comment[][];
  showComments: boolean;
  setShowComments: (val: boolean) => void;
  conversationId?: string;
  searchTerm?: string;
  onCommentChange: () => void;
  onHeightChange: () => void;
}

const CommentsSection: React.FC<CommentsSectionProps> = ({
  threads,
  showComments,
  setShowComments,
  conversationId,
  searchTerm,
  onCommentChange,
  onHeightChange,
}) => {

  return (
    <Collapse
      in={showComments && threads.length > 0}
      timeout="auto"
      unmountOnExit
      onTransitionEnd={onHeightChange}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          zIndex: "20",
        }}
      >
        {threads.map((threadComments) => (
          <ThreadComponent
            key={threadComments[0].thread_id}
            comments={threadComments}
            conversationId={conversationId || ""}
            searchTerm={searchTerm}
            onChangeComment={onCommentChange}
            onHeightChange={onHeightChange}
          />
        ))}
      </Box>
    </Collapse>
  );
};

export default CommentsSection;
