import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { RolodexPerson, RolodexPersonData } from "../reducers/rolodexReducer";

export const fetchRolodex = createAsyncThunk(
  "rolodex/fetchRolodex",
  async () => {
    const response = await api("v1/admin/rolodex-people", "GET");
    return response as RolodexPerson[];
  }
);

interface UpdateRolodexParams {
  id: string;
  updates: Partial<RolodexPerson>;
}

export const updateRolodexPerson = createAsyncThunk(
  "rolodex/updateRolodexPerson",
  async ({ id, updates }: UpdateRolodexParams) => {
    const response = await api(`v1/admin/rolodex-people/${id}`, "PUT", updates);
    return response as RolodexPerson;
  }
);

interface CreateRolodexParams {
  conversation_ids: string[];
  data: RolodexPersonData;
}

export const createRolodexPerson = createAsyncThunk(
  "rolodex/createRolodexPerson",
  async ({ conversation_ids, data }: CreateRolodexParams) => {
    const requestBody = { conversation_ids, data };
    const response = await api("v1/admin/rolodex-people", "POST", requestBody);
    return response as RolodexPerson;
  }
);

export const publishRolodexPerson = createAsyncThunk(
  "rolodex/publishRolodexPerson",
  async ({ id }: { id: string }) => {
    // The new API endpoint: POST /v1/admin/rolodex-people/:id/publish
    const response = await api(`v1/admin/rolodex-people/${id}/publish`, "POST");
    return response as RolodexPerson;
  }
);
