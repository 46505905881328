import React, { useEffect, useMemo, useState, KeyboardEvent } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Modal,
  Tooltip,
  Chip,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  editComment,
  deleteComment,
  addComment,
} from "../../../redux/actions/conversationsActions";
import { Comment } from "../../../redux/reducers/conversationsReducer";
import { format } from "date-fns";
import { getFullNameFromUserName } from "../../../utils/render";
import { hashTagToColor } from "../../../utils/tagColorUtils";

interface CommentComponentProps {
  comment: Comment;
  conversationId: string;
  isReplyable: boolean;
  searchTerm?: string;
  onChangeComment?: () => void;
  onHeightChange?: () => void;
}

const CommentComponent: React.FC<CommentComponentProps> = ({
  comment,
  conversationId,
  isReplyable,
  searchTerm,
  onChangeComment,
  onHeightChange,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: RootState) => state.base.user);
  const isAuthor = currentUser?.id === comment.user.id;
  const canDeleteAny = currentUser?.scopes?.includes(
    "annotationcomment:delete_any"
  );

  const [isEditing, setIsEditing] = useState(false);
  const [editedCommentText, setEditedCommentText] = useState(comment.comment);
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editTags, setEditTags] = useState<string[]>(comment.tags ?? []);
  const [editTagInput, setEditTagInput] = useState("");
  const [replyTags, setReplyTags] = useState<string[]>([]);
  const [replyTagInput, setReplyTagInput] = useState("");

  useEffect(() => {
    onHeightChange?.();
  }, [onHeightChange, isReplying, isEditing]);

  const handleEditComment = () => {
    if (editedCommentText.trim() !== "") {
      dispatch(
        editComment({
          conversationId: conversationId,
          commentId: comment.id,
          comment: editedCommentText,
          tags: editTags.length > 0 ? editTags : [],
        })
      );
      setIsEditing(false);
      onChangeComment?.();
      onHeightChange?.();
    }
  };

  const highlightedCommentText = useMemo(() => {
    if (!searchTerm || !comment.comment) return comment.comment;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return comment.comment.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }, [searchTerm, comment.comment]);

  const [showTooltip, setShowTooltip] = useState(false);
  const commentTarget = `cmt:${comment.id}`;

  const handleShareComment = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_LOGIN_REDIRECT_URL}/conversations/${conversationId}?target_id=${commentTarget}`
    );
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  const handleDeleteComment = () => {
    dispatch(
      deleteComment({ conversationId: conversationId, commentId: comment.id })
    );
    setShowDeleteModal(false);
    onChangeComment?.();
    onHeightChange?.();
  };

  const handleAddReply = () => {
    if (replyText.trim() !== "") {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: comment.target_id,
          comment: replyText,
          threadId: comment.thread_id,
          tags: replyTags.length > 0 ? replyTags : [],
        })
      );
      setReplyText("");
      setReplyTags([]);
      setIsReplying(false);
      onChangeComment?.();
      onHeightChange?.();
    }
  };

  const formattedDate = comment.updated_at
    ? `${format(new Date(comment.updated_at), "MMM d, yyyy HH:mm")} (edited)`
    : format(new Date(comment.created_at), "MMM d, yyyy HH:mm");

  const renderTagsWithHeading = (
    tags: string[],
    canDelete?: (tag: string) => void
  ) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 1,
        mt: 1,
      }}
    >
      {canDelete ? (
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mr: 1 }}>
          Tags:
        </Typography>
      ) : null}
      {tags.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          size="small"
          sx={{
            backgroundColor: hashTagToColor(tag),
            color: "white",
          }}
          onDelete={canDelete ? () => canDelete(tag) : undefined}
        />
      ))}
    </Box>
  );

  const addEditTag = () => {
    const newTag = editTagInput.trim();
    if (newTag && !editTags.includes(newTag)) {
      setEditTags([...editTags, newTag]);
    }
    setEditTagInput("");
  };

  const removeEditTag = (tag: string) => {
    setEditTags(editTags.filter((t) => t !== tag));
  };

  const handleEditTagKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addEditTag();
    }
  };

  const addReplyTag = () => {
    const newTag = replyTagInput.trim();
    if (newTag && !replyTags.includes(newTag)) {
      setReplyTags([...replyTags, newTag]);
    }
    setReplyTagInput("");
  };

  const removeReplyTag = (tag: string) => {
    setReplyTags(replyTags.filter((t) => t !== tag));
  };

  const handleReplyTagKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addReplyTag();
    }
  };

  return (
    <Box
      id={commentTarget}
      sx={{
        backgroundColor: "#fff",
        p: 2,
        borderRadius: 2,
        mb: 2,
        maxWidth: "100%",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
        {getFullNameFromUserName(comment.user.name)}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          fontSize: 12,
          color: "gray",
        }}
      >
        {formattedDate}
      </Typography>

      {!isEditing ? (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {highlightedCommentText}
        </Typography>
      ) : (
        <>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={editedCommentText}
            onChange={(e) => setEditedCommentText(e.target.value)}
            sx={{ mt: 1 }}
            label="Edit Comment"
          />
          {renderTagsWithHeading(editTags, removeEditTag)}
          <Box sx={{ display: "flex", gap: 1, mt: 1, alignItems: "center" }}>
            <TextField
              variant="outlined"
              size="small"
              value={editTagInput}
              onChange={(e) => setEditTagInput(e.target.value)}
              onKeyDown={handleEditTagKeyDown}
              placeholder="Type a tag and press Enter"
              sx={{ flex: 1 }}
            />
            <Button variant="contained" onClick={addEditTag}>
              Add Tag
            </Button>
          </Box>
        </>
      )}

      {!isEditing && comment.tags && comment.tags.length > 0 && (
        <>{renderTagsWithHeading(comment.tags)}</>
      )}

      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
        {!isAuthor && canDeleteAny && (
          <IconButton size="small" onClick={() => setShowDeleteModal(true)}>
            <DeleteIcon />
          </IconButton>
        )}

        {isAuthor && (
          <>
            {!isEditing ? (
              <>
                <IconButton
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                    setIsReplying(false);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleEditComment}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        )}
        {!isEditing && isReplyable && (
          <Button
            size="small"
            startIcon={<ReplyIcon />}
            onClick={() => {
              setIsReplying(!isReplying);
              setIsEditing(false);
            }}
          >
            Reply
          </Button>
        )}
        <Box sx={{ ml: "auto" }}>
          <Tooltip
            title={showTooltip ? "Copied to clipboard" : "Share comment"}
            open={showTooltip}
            onClose={() => setShowTooltip(false)}
          >
            <IconButton size="small" onClick={handleShareComment}>
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {isReplying && (
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            sx={{ mb: 1 }}
            label="Reply"
          />
          {renderTagsWithHeading(replyTags, removeReplyTag)}
          <Box sx={{ display: "flex", gap: 1, mt: 1, alignItems: "center" }}>
            <TextField
              variant="outlined"
              size="small"
              value={replyTagInput}
              onChange={(e) => setReplyTagInput(e.target.value)}
              onKeyDown={handleReplyTagKeyDown}
              placeholder="Press Enter to add tag"
              sx={{ flex: 1 }}
            />
            <Button variant="contained" onClick={addReplyTag}>
              Add Tag
            </Button>
          </Box>

          <Button
            size="small"
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleAddReply}
          >
            Submit Reply
          </Button>
        </Box>
      )}

      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Confirm Deletion
          </Typography>
          <Typography
            id="delete-confirmation-description"
            sx={{ mb: 3 }}
            variant="body1"
          >
            Are you sure you want to delete this comment?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteComment}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CommentComponent;
