import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import indexedDBStorage from "redux-persist-indexeddb-storage";
import expireReducer from "redux-persist-transform-expire";
// import compress from "redux-persist-transform-compress";

// Only import logger in development mode
let logger: any;
if (process.env.NODE_ENV === "development") {
  logger = require("redux-logger").default;
}

// Configure redux-persist with IndexedDB and expiration transform
const persistConfig = {
  key: "root",
  storage: indexedDBStorage("AdminApp"),
  whitelist: ["conversations"],
  transforms: [
    expireReducer("conversations", {
      expireSeconds: 1800, // Expire after 30 minutes
    }),
    // compress(), // IF REQUIRED IN THE FUTURE
  ],
};

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    if (process.env.NODE_ENV === "development" && logger) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(logger);
    }
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

// Configure persistor
export const persistor = persistStore(store);

// Detect hard refresh using performance API and sessionStorage
const navigationEntry = performance.getEntriesByType(
  "navigation"
)[0] as PerformanceNavigationTiming;
const isReload = navigationEntry
  ? navigationEntry.type === "reload"
  : performance.navigation.type === 1;

if (isReload) {
  // Purge cache on hard refresh
  persistor.purge();
}

// Export types for dispatch and state
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
