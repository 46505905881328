import React from "react";
import {
  Box,
  Typography,
  Chip,
  IconButton,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { hashTagToColor } from "../../../../utils/tagColorUtils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface FilterToolbarProps {
  allTags: string[];
  activeTags: Set<string>;
  toggleTag: (tag: string) => void;
  commenters: { id: string; full_name: string }[];
  selectedCommenterId: string | null;
  setSelectedCommenterId: (id: string | null) => void;
  filterSearchResults: number[];
  currentFilterIndex: number;
  nextFilterResult: () => void;
  prevFilterResult: () => void;
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({
  allTags,
  activeTags,
  toggleTag,
  commenters,
  selectedCommenterId,
  setSelectedCommenterId,
  filterSearchResults,
  currentFilterIndex,
  nextFilterResult,
  prevFilterResult,
}) => {
  if (allTags.length === 0 && commenters.length === 0) return null;

  return (
    <Box sx={{ mb: 2 }}>
      {/* Annotator Filter */}
      {commenters.length > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Annotator:
          </Typography>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <Select
              value={selectedCommenterId || ""}
              onChange={(e) =>
                setSelectedCommenterId(
                  e.target.value === "" ? null : e.target.value
                )
              }
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>
              {commenters.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.full_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Navigation Controls */}
          {filterSearchResults.length > 0 && (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <IconButton
                size="small"
                onClick={prevFilterResult}
                disabled={filterSearchResults.length === 0}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="caption">
                {filterSearchResults.length > 0
                  ? `${currentFilterIndex + 1}/${filterSearchResults.length}`
                  : "0/0"}
              </Typography>
              <IconButton
                size="small"
                onClick={nextFilterResult}
                disabled={filterSearchResults.length === 0}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}

      {/* Tags Filter */}
      {allTags.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Tags:
          </Typography>
          {allTags.map((tag) => {
            const selected = activeTags.has(tag);
            return (
              <Chip
                key={tag}
                label={tag}
                onClick={() => toggleTag(tag)}
                sx={{
                  backgroundColor: hashTagToColor(tag),
                  color: "white",
                  opacity: selected ? 1 : 0.5,
                  cursor: "pointer",
                  border: selected ? "2px solid #333" : "none",
                }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default FilterToolbar;
