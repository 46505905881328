// FeedbackSection.tsx
import React from "react";
import ViewOverallFeedback from "../feedback/ViewOverallFeedback";

interface FeedbackSectionProps {
  conversationId: string;
  getCommentsForMessage: (targetId: string) => any[];
}

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  conversationId,
  getCommentsForMessage,
}) => {
  return (
    <ViewOverallFeedback
      conversationId={conversationId}
      comments={getCommentsForMessage(`conv:${conversationId}`)}
    />
  );
};

export default FeedbackSection;
