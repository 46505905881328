import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface StructuredLogsViewerProps {
  call: any;
}

const StructuredLogsViewer: React.FC<StructuredLogsViewerProps> = ({
  call,
}) => {
  const [copyText, setCopyText] = useState("Copy Prompt");

  if (!call) {
    return <Typography>No call selected.</Typography>;
  }

  const llmEvent = call.llm_inference_event;
  const modelEvent = call.model_inference_event;
  const ttsEvent = call.text_to_speech_saved_event;

  let title = "Unknown Call";
  let detailsContent = <Typography>No details available.</Typography>;

  const handleCopyPrompt = () => {
    if (llmEvent?.chat_prompt_messages) {
      const fullPrompt = llmEvent.chat_prompt_messages
        .map((message: any) => `${message.content}`)
        .join("\n\n");
      navigator.clipboard.writeText(fullPrompt);
      setCopyText("Copied!");
      setTimeout(() => setCopyText("Copy Prompt"), 2000); // Reset tooltip text after 2 seconds
    }
  };

  if (llmEvent) {
    title = `Model: ${llmEvent.model_name || "Unknown"}`;

    detailsContent = (
      <Box>
        {llmEvent?.chat_prompt_messages && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Prompt Messages:</Typography>
            {llmEvent.chat_prompt_messages.map((message: any, idx: number) => (
              <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {message.role}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {message.content}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {llmEvent?.output && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Output:</Typography>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {llmEvent.output}
            </Typography>
          </Box>
        )}
        {llmEvent?.model_key && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Model Key:</Typography>
            <Typography variant="body2">{llmEvent.model_key}</Typography>
          </Box>
        )}
        {llmEvent?.model_provider && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Model Provider:</Typography>
            <Typography variant="body2">{llmEvent.model_provider}</Typography>
          </Box>
        )}
        {llmEvent?.temperature !== undefined && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Temperature:</Typography>
            <Typography variant="body2">{llmEvent.temperature}</Typography>
          </Box>
        )}

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">More Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {JSON.stringify(call, null, 2)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  } else if (modelEvent) {
    title = `Model Key: ${modelEvent.model_key || "Unknown"}`;

    detailsContent = (
      <Box>
        {modelEvent?.model_id && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Model ID:</Typography>
            <Typography variant="body2">{modelEvent.model_id}</Typography>
          </Box>
        )}

        {modelEvent?.inputs && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Inputs:</Typography>
            {modelEvent.inputs.repeated_query?.queries?.map(
              (query: string, idx: number) => (
                <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {query}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        )}

        {modelEvent?.outputs && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Outputs:</Typography>
            {modelEvent.outputs.outputs?.map((output: any, idx: number) => {
              let outputJson = {};
              if (output.json?.json) {
                try {
                  outputJson = JSON.parse(output.json.json);
                } catch (e) {
                  console.error("Error parsing output JSON:", e);
                }
              }
              return (
                <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                  {Object.entries(outputJson).map(([key, value]) => (
                    <Typography
                      key={key}
                      variant="body2"
                      sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                    >
                      {`${key}: ${value}`}
                    </Typography>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">More Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {JSON.stringify(call, null, 2)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  } else if (ttsEvent) {
    const diagnostics = ttsEvent?.diagnostics;

    title = `TTS Saved Event - Blob: ${ttsEvent.blob_path || "Unknown"}`;
    detailsContent = (
      <Box>
        <Typography variant="subtitle1">Blob Information:</Typography>
        <Typography variant="body2">
          Container: {ttsEvent?.blob_container || "N/A"}
        </Typography>
        <Typography variant="body2">
          Path: {ttsEvent?.blob_path || "N/A"}
        </Typography>

        {diagnostics && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Diagnostics:
            </Typography>
            <Typography variant="body2">
              Provider: {diagnostics?.provider || "N/A"}
            </Typography>
            <Typography variant="body2">
              Transcript: {diagnostics?.transcript || "N/A"}
            </Typography>
            <Typography variant="body2">
              Spoken Transcript: {diagnostics?.spoken_transcript || "N/A"}
            </Typography>
            <Typography variant="body2">
              Emotion: {diagnostics?.emotion || "N/A"}
            </Typography>
            <Typography variant="body2">
              Model ID: {diagnostics?.model_id || "N/A"}
            </Typography>
            <Typography variant="body2">
              Volume: {diagnostics?.volume || "N/A"}
            </Typography>
            <Typography variant="body2">
              Speed: {diagnostics?.speed || "N/A"}
            </Typography>
            <Typography variant="body2">
              Stability: {diagnostics?.stability || "N/A"}
            </Typography>
            <Typography variant="body2">
              Similarity Boost: {diagnostics?.similarity_boost || "N/A"}
            </Typography>
            <Typography variant="body2">
              Style Boost: {diagnostics?.style_boost || "N/A"}
            </Typography>
          </>
        )}

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">More Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {JSON.stringify(call, null, 2)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  } else {
    // Unknown event type
    detailsContent = (
      <Box>
        <Typography variant="body2">
          No specific display logic for this event type.
        </Typography>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">More Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {JSON.stringify(call, null, 2)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return (
    <Box>
      {/* Title Section with Copy Prompt Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {llmEvent?.chat_prompt_messages && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopyPrompt}
            disabled={copyText === "Copied!"}
          >
            {copyText}
          </Button>
        )}
      </Box>

      {detailsContent}
    </Box>
  );
};

export default StructuredLogsViewer;
