// DateDrawer.tsx
import React from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

interface DateDrawerProps {
  showDateDrawer: boolean;
  setShowDateDrawer: (val: boolean) => void;
  dates: string[];
  selectedDate: string | null;
  scrollToDate: (date: string) => void;
}

const DateDrawer: React.FC<DateDrawerProps> = ({
  showDateDrawer,
  setShowDateDrawer,
  dates,
  selectedDate,
  scrollToDate,
}) => {
  return (
    <Box
      sx={{
        width: showDateDrawer ? "20%" : "0",
        transition: "width 0.3s",
        position: "relative",
      }}
    >
      {showDateDrawer && (
        <Box
          sx={{
            borderLeft: "1px solid #ccc",
            backgroundColor: "#f0f0f0",
            padding: "20px",
            minWidth: "200px",
            height: "95vh",
            overflowY: "auto",
            borderRadius: "16px",
            width: "85%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Select a Date
          </Typography>
          <List>
            {dates.map((date, index) => (
              <ListItem
                button
                key={index}
                selected={selectedDate === date}
                onClick={() => scrollToDate(date)}
              >
                <ListItemText primary={date} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <IconButton
        onClick={() => setShowDateDrawer(!showDateDrawer)}
        sx={{
          position: "absolute",
          top: "50%",
          left: showDateDrawer ? "-16px" : "-32px",
          transform: "translateY(-50%)",
          backgroundColor: "white",
          borderRadius: "50%",
          boxShadow: 1,
          zIndex: 1000,
        }}
      >
        {showDateDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </Box>
  );
};

export default DateDrawer;
