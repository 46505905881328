import React, { useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Comment } from "../../../../redux/reducers/conversationsReducer";
import ThreadComponent from "../ThreadComponent"; // Assuming you have this component

interface ViewOverallFeedbackProps {
  conversationId: string;
  comments: Comment[];
}

const ViewOverallFeedback: React.FC<ViewOverallFeedbackProps> = ({
  conversationId,
  comments = [],
}) => {
  const [showComments, setShowComments] = useState(false);

  const handleToggleComments = () => {
    setShowComments((prev) => !prev);
  };

  return (
    <>
      {comments.length > 0 && (
        <Box style={{ marginBottom: "16px" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Overall Feedback</Typography>
            <IconButton
              onClick={handleToggleComments}
              aria-label="toggle comments"
            >
              {showComments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          {/* Collapse for showing/hiding the thread */}
          <Collapse in={showComments} timeout="auto" unmountOnExit>
            <Box
              sx={{
                width: "95%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                zIndex: "20",
                mt: 2,
                p: 2,
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <ThreadComponent
                comments={comments}
                conversationId={conversationId}
              />
            </Box>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default ViewOverallFeedback;
