import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {
  fetchRolodex,
  updateRolodexPerson,
  createRolodexPerson,
  publishRolodexPerson,
} from "../actions/rolodexActions";

export interface RolodexPersonName {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
}

export interface RolodexPersonData {
  name: RolodexPersonName;
  summary?: string | null;
}

export interface RolodexPerson {
  id: string;
  conversation_ids: string[];
  data: RolodexPersonData;
  created_at: string;
  updated_at?: string | null;
  version: number;
  published_version: number;
  published_conversation_ids: string[];
}

export interface RolodexState {
  rolodex: RolodexPerson[];
  loading: boolean;
  error: string | null;
}

const initialState: RolodexState = {
  rolodex: [],
  loading: false,
  error: null,
};

const rolodexSlice = createSlice({
  name: "rolodex",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<RolodexState>) => {
    // ----- fetchRolodex -----
    builder
      .addCase(fetchRolodex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchRolodex.fulfilled,
        (state, action: PayloadAction<RolodexPerson[]>) => {
          state.loading = false;
          state.rolodex = action.payload;
        }
      )
      .addCase(fetchRolodex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch Rolodex data";
      });

    // ----- updateRolodexPerson -----
    builder.addCase(
      updateRolodexPerson.fulfilled,
      (state, action: PayloadAction<RolodexPerson>) => {
        const updatedPerson = action.payload;
        const index = state.rolodex.findIndex((p) => p.id === updatedPerson.id);
        if (index !== -1) {
          state.rolodex[index] = updatedPerson;
        }
      }
    );
    // ----- publishRolodexPerson -----
    builder.addCase(
      publishRolodexPerson.fulfilled,
      (state, action: PayloadAction<RolodexPerson>) => {
        const updatedPerson = action.payload;
        const index = state.rolodex.findIndex((p) => p.id === updatedPerson.id);
        if (index !== -1) {
          state.rolodex[index] = updatedPerson;
        }
      }
    );

    // ----- createRolodexPerson -----
    builder.addCase(
      createRolodexPerson.fulfilled,
      (state, action: PayloadAction<RolodexPerson>) => {
        state.rolodex.push(action.payload);
      }
    );
  },
});

export default rolodexSlice.reducer;
