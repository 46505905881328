import React from "react";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import {
  TextFields as TextFieldsIcon,
  Mic as MicIcon,
  Videocam as VideocamIcon,
  Movie as MovieIcon,
  Image as ImageIcon,
  CloudDownload as CloudDownloadIcon,
} from "@mui/icons-material";
import AudioPlayer from "../audio/AudioPlayer";
import {
  Message,
  Comment,
} from "../../../../redux/reducers/conversationsReducer";
import { ChatMessageModality } from "./MessageBubbleTypes";
import MessageActions from "./MessageActions";

interface MessageContentProps {
  message: Message;
  isRightAligned: boolean;
  canViewAllData?: boolean;
  imageUrl: string | null;
  loadingImage: boolean;
  fetchImage: () => void;
  highlightedContent: React.ReactNode;
  formattedDate: string;
  canViewMessageAudio?: boolean;
  showShareTooltip: boolean;
  handleShareMessage: () => void;
  comments: Comment[];
  showComments: boolean;
  setShowComments: (val: boolean) => void;
  canViewModelCalls?: boolean;
  handleShowLogsModal: () => void;
  setShowCommentModal: (val: boolean) => void;
  isHighlighted?: boolean;
  speakerName?: string; // <-- Make sure we have speakerName here
}

const renderModalityIcon = (modality: ChatMessageModality) => {
  switch (modality) {
    case ChatMessageModality.Text:
      return <TextFieldsIcon />;
    case ChatMessageModality.Audio:
      return <MicIcon />;
    case ChatMessageModality.Video:
      return <VideocamIcon />;
    case ChatMessageModality.AudioVideo:
      return <MovieIcon />;
  }
};

const MessageContent: React.FC<MessageContentProps> = ({
  message,
  isRightAligned,
  canViewAllData,
  imageUrl,
  loadingImage,
  fetchImage,
  highlightedContent,
  formattedDate,
  canViewMessageAudio,
  showShareTooltip,
  handleShareMessage,
  comments,
  showComments,
  setShowComments,
  canViewModelCalls,
  handleShowLogsModal,
  setShowCommentModal,
  isHighlighted,
  speakerName,
}) => {
  // Decide bubble color
  const bubbleColor = isHighlighted
    ? "#fff9c4" // highlighted
    : isRightAligned
    ? "#DCF8C6"
    : "#F0F0F0";

  // Bubble styles (relative so label can be absolute)
  const bubbleStyles = {
    position: "relative" as const,
    backgroundColor: bubbleColor,
    color: "black",
    padding: "12px",
    borderRadius: "12px",
    maxWidth: "100%",
    marginBottom: "8px",
    alignSelf: isRightAligned ? "flex-end" : "flex-start",
    transition: "background-color 2s ease",
  };

  return (
    <Box sx={bubbleStyles}>
      {/* 
        Speaker name pinned to top corner inside the bubble.
        If isRightAligned, put it top-right; else top-left.
      */}
      {speakerName && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: isRightAligned ? 12 : "auto",
            right: isRightAligned ? "auto" : 12,
            backgroundColor: "#E7F1FF",
            padding: "2px 8px",
            borderRadius: "4px",
            fontSize: "0.75rem",
            fontWeight: 600,
            boxShadow: "0 1px 2px rgba(0,0,0,0.2)",
          }}
        >
          {speakerName}
        </Box>
      )}

      {/* Main text (if any) */}
      {message.content && (
        <Typography
          variant="body1"
          textAlign={isRightAligned ? "right" : "left"}
          marginTop={"4px"}
        >
          {highlightedContent}
        </Typography>
      )}

      {loadingImage && <Typography>Loading image...</Typography>}

      {!imageUrl &&
        message.media_ref?.blob_path &&
        (canViewAllData ? (
          <Button variant="outlined" onClick={fetchImage}>
            Download Image
          </Button>
        ) : (
          <Typography variant="body1">{"<Hidden Image>"}</Typography>
        ))}

      {imageUrl && (
        <img src={imageUrl} alt="Media" style={{ maxWidth: "100%" }} />
      )}

      {/* Timestamp */}
      <Typography
        color="textSecondary"
        textAlign={isRightAligned ? "right" : "left"}
        fontSize={10}
      >
        {formattedDate}
      </Typography>

      {/* Bottom row for modality, audio, actions */}
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        {/* Show icon if message is not purely text */}
        {message.modality && message.modality !== ChatMessageModality.Text && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderModalityIcon(message.modality)}
            {message.stored_audio && canViewMessageAudio ? (
              <AudioPlayer
                conversationId=""
                storedAudio={message.stored_audio}
                isRightAligned={isRightAligned}
              />
            ) : message.has_stored_audio ? (
              <Tooltip
                title={
                  canViewMessageAudio
                    ? "Switch to real-data view to download audio"
                    : "Audio File not available"
                }
                arrow
              >
                <span>
                  <IconButton
                    disabled
                    style={{ padding: "0px", marginLeft: "8px" }}
                  >
                    <CloudDownloadIcon style={{ fontSize: 24 }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
          </Box>
        )}

        {message.content_type === "image_with_description" && (
          <Box sx={{ marginLeft: "8px" }}>
            <ImageIcon />
          </Box>
        )}

        {isRightAligned &&
          message.percent_spoken &&
          message.percent_spoken < 1.0 && (
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                backgroundColor: "red",
                color: "white",
                borderRadius: "12px",
                padding: "2px 8px",
                fontSize: "12px",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              Completion: {Math.round(message.percent_spoken * 100)}%
            </Box>
          )}

        {/* Actions on the far right (comments, logs, share, etc.) */}
        <Box sx={{ marginLeft: "auto" }}>
          <MessageActions
            message={message}
            comments={comments}
            showComments={showComments}
            setShowComments={setShowComments}
            canViewModelCalls={canViewModelCalls}
            handleShowLogsModal={handleShowLogsModal}
            handleShareMessage={handleShareMessage}
            showShareTooltip={showShareTooltip}
            setShowCommentModal={setShowCommentModal}
            canViewAllData={canViewAllData}
            isRightAligned={isRightAligned}
            conversationId=""
            chatSessionId=""
            canViewMessageAudio={canViewMessageAudio}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageContent;
