import React, { useState, KeyboardEvent } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { addComment } from "../../../../redux/actions/conversationsActions";
import { hashTagToColor } from "../../../../utils/tagColorUtils";

interface AddOverallFeedbackProps {
  conversationId: string;
}

const AddOverallFeedback: React.FC<AddOverallFeedbackProps> = ({
  conversationId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");

  // State for tags
  const [tags, setTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState("");

  const addTag = () => {
    const newTag = tagInput.trim();
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
    }
    setTagInput("");
  };

  const removeTag = (tag: string) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    }
  };

  const handleAddComment = () => {
    if (commentText.trim() !== "") {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: `conv:${conversationId}`,
          comment: commentText,
          tags: tags.length > 0 ? tags : [],
        })
      );
      setCommentText("");
      setTags([]);
      setShowCommentModal(false);
    }
  };

  return (
    <>
      {/* Button to open comment modal */}
      <Button
        variant="contained"
        startIcon={<AddCommentIcon />}
        onClick={() => setShowCommentModal(true)}
        sx={{ marginLeft: 2 }}
      >
        Add Feedback
      </Button>

      {/* Modal to add a new comment */}
      <Modal open={showCommentModal} onClose={() => setShowCommentModal(false)}>
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" component="h2">
              Add Comment
            </Typography>
            <IconButton
              style={{ marginTop: "-4px" }}
              onClick={() => setShowCommentModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            sx={{ mt: 2 }}
            label="Comment"
          />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mt: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" sx={{ mt: 0, fontWeight: "bold" }}>
              Tags:
            </Typography>
            {tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                size="small"
                sx={{
                  backgroundColor: hashTagToColor(tag),
                  color: "white",
                }}
                onDelete={() => removeTag(tag)}
              />
            ))}
          </Box>

          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type a tag and press Enter"
            />
            <Button variant="contained" onClick={addTag}>
              Add Tag
            </Button>
          </Box>

          <Button variant="contained" onClick={handleAddComment} sx={{ mt: 2 }}>
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AddOverallFeedback;
