import React from "react";
import { Box, Typography, Button, Collapse } from "@mui/material";
import { format } from "date-fns";
import AddOverallFeedback from "../feedback/AddOverallFeedback";

interface DetailsSectionProps {
  showDetails: boolean;
  conversation: any;
  transcript: any;
  canReadConversation?: boolean;
  showRealData: boolean;
  canViewUser?: boolean;
  handleViewUser: (userId: string | null) => void;
  handleToggleView: () => void;
  handleShadowBan: (val: boolean) => void;
  conversationId?: string;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  showDetails,
  conversation,
  transcript,
  canReadConversation,
  showRealData,
  canViewUser,
  handleViewUser,
  handleToggleView,
  handleShadowBan,
  conversationId,
}) => {
  const renderDetails = () => {
    if (!canReadConversation || !showRealData) {
      return (
        <>
          <Typography variant="subtitle1">
            <strong>Human Initial:</strong>{" "}
            {transcript?.human_initial || conversation?.human?.full_name?.[0]}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Bot Name:</strong>{" "}
            {transcript?.bot_name || conversation?.bot?.name}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Conversation ID:</strong>{" "}
            {transcript?.conversation_id || conversation?.id}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Created At:</strong>{" "}
            {(transcript || conversation) &&
              format(
                new Date(
                  transcript?.created_at || conversation?.created_at || ""
                ),
                "yyyy-MM-dd HH:mm:ss"
              )}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Environment:</strong>{" "}
            {transcript?.environment || conversation?.environment}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle1">
          <strong>Human:</strong> {conversation?.human.first_name} (ID:{" "}
          {conversation?.human.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Bot:</strong> {conversation?.bot.name} (ID:{" "}
          {conversation?.bot.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Conversation ID:</strong> {conversation?.id}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Created At:</strong>{" "}
          {format(
            new Date(conversation?.created_at || ""),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Private:</strong> {conversation?.is_private ? "Yes" : "No"}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Environment:</strong> {conversation?.environment}
        </Typography>
      </>
    );
  };

  return (
    <Collapse in={showDetails} timeout={2000} unmountOnExit>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Conversation Details
        </Typography>
        {renderDetails()}

        <Box mt={4} display="flex" alignItems="center">
          {conversation?.owning_user_id && canViewUser && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleViewUser(conversation.owning_user_id ?? "")}
            >
              View Conversation User
            </Button>
          )}

          {canReadConversation && (
            <Button
              variant="contained"
              color="warning"
              onClick={handleToggleView}
              sx={{ marginLeft: conversation?.owning_user_id ? 2 : 0 }}
            >
              {showRealData ? "View Anonymized Data" : "View Real Data"}
            </Button>
          )}
          <AddOverallFeedback conversationId={conversationId || ""} />
          <Button
            variant="contained"
            color="error"
            onClick={() => handleShadowBan(!conversation?.shadow_banned)}
            sx={{ marginLeft: 2 }}
          >
            {conversation?.shadow_banned ? "Lift ShadowBan" : "ShadowBan"}
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export default DetailsSection;
