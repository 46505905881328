import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api_middleware";
import { User, UserStatus } from "../reducers/userReducer";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await api("v1/admin/users", "GET");
  return response;
});

interface UpdateUserStatusParams {
  id: string;
  status: UserStatus;
  deactivation_reason?: string;
  user?: User;
}

export const updateUserStatus = createAsyncThunk(
  "users/updateUserStatus",
  async ({ id, status, deactivation_reason, user }: UpdateUserStatusParams) => {
    const response = await api(`v1/admin/users/${id}`, "PUT", {
      ...user,
      status,
      deactivation_reason,
    });
    return response;
  }
);

