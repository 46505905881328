import React, { useEffect, useState } from "react";
import { Box, IconButton, CircularProgress } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CryptoJS from "crypto-js";

interface AudioPlayerProps {
  conversationId: string;
  storedAudio: {
    path: string;
    container: string;
  };
  isRightAligned?: boolean;
  externalAudioUrl?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  conversationId,
  storedAudio,
  isRightAligned,
  externalAudioUrl,
}) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(
    externalAudioUrl || null
  );
  const [loadingAudio, setLoadingAudio] = useState<boolean>(false);

  useEffect(() => {
    // If externalAudioUrl changes for some reason, update state
    if (externalAudioUrl) {
      setAudioUrl(externalAudioUrl);
    }
  }, [externalAudioUrl]);

  // TODO: Refactor this to a proper action but keeping here since we might want to do things with this like streaming
  const fetchAudio = async () => {
    setLoadingAudio(true);
    try {
      const encryptedJwt = localStorage.getItem("jwt");
      let jwt = null;

      if (encryptedJwt) {
        const bytes = CryptoJS.AES.decrypt(
          encryptedJwt,
          process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
        );
        jwt = bytes.toString(CryptoJS.enc.Utf8);
      }

      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };

      if (jwt) {
        headers["Authorization"] = `Bearer ${jwt}`;
      }

      // Construct request body
      const requestBody = {
        blob_path: storedAudio.path,
        container: storedAudio.container,
        wait_for_generation_timeout_sec: 20,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_BACKEND_URL}v1/admin/download_media`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download audio");
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
    } catch (error) {
      console.error("Error fetching audio:", error);
    } finally {
      setLoadingAudio(false);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {!audioUrl && (
        <IconButton
          style={{ padding: "0px", marginLeft: "8px" }}
          onClick={fetchAudio}
          disabled={loadingAudio}
        >
          {loadingAudio ? (
            <CircularProgress size={24} />
          ) : (
            <CloudDownloadIcon style={{ color: "black", fontSize: 24 }} />
          )}
        </IconButton>
      )}
      {audioUrl && (
        <Box style={{ marginLeft: "0px" }}>
          <audio
            className={isRightAligned ? "audio-bot" : "audio-human"}
            style={{ height: "20px", verticalAlign: "bottom" }}
            controls
            src={audioUrl}
          />
        </Box>
      )}
    </Box>
  );
};

export default AudioPlayer;
