// StructuredLogsModal.tsx

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import StructuredLogsViewer from "../StructuredLogsViewer";
import { Message } from "../../../../redux/reducers/conversationsReducer";
import { format } from "date-fns";

interface ContextMessagesProps {
  messages: Message[];
  highlightMessageId?: number;
  getSpeakerName: (msg: Message) => string;
}

const ContextMessages: React.FC<ContextMessagesProps> = ({
  messages,
  highlightMessageId,
  getSpeakerName,
}) => {
  const highlightedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // After rendering, scroll to highlighted message if any
    if (highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [messages, highlightMessageId]);

  return (
    <Box
      sx={{
        height: "200px",
        overflowY: "auto",
        mb: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        Context Around This Message
      </Typography>
      {messages.map((msg) => {
        const currentId = msg.id ?? msg.chat_message_id;
        const isHighlighted = currentId === highlightMessageId;
        const bgColor = isHighlighted
          ? "#fff9c4"
          : msg.type === "human"
          ? "#F0F0F0"
          : "#DCF8C6";

        const textAlign = msg.type === "human" ? "left" : "right";
        const formattedDate = format(new Date(msg.created_at), "PPpp");

        const speakerName = getSpeakerName(msg);

        return (
          <Box
            key={`${msg.chat_session_id}-${currentId}`}
            ref={isHighlighted ? highlightedRef : null}
            sx={{
              p: 1,
              mt: 1,
              borderRadius: 1,
              backgroundColor: bgColor,
              border: "none",
              textAlign: textAlign,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              {speakerName} - {formattedDate}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {msg.content}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

interface StructuredLogsModalProps {
  showLogsModal: boolean;
  setShowLogsModal: (val: boolean) => void;
  structuredLogs: { event_logs?: any } | undefined;
  loadingStructuredLogs: boolean;
  message: Message;
  getContextMessages: (messageId: number, chatSessionId: string) => Message[];
  getSpeakerName: (msg: Message) => string;
}

const StructuredLogsModal: React.FC<StructuredLogsModalProps> = ({
  showLogsModal,
  setShowLogsModal,
  structuredLogs,
  loadingStructuredLogs,
  message,
  getContextMessages,
  getSpeakerName,
}) => {
  const [llmCalls, setLlmCalls] = useState<any[]>([]);
  const [modelCalls, setModelCalls] = useState<any[]>([]);
  const [TTSEvents, setTTSEvents] = useState<any[]>([]);

  const [selectedCall, setSelectedCall] = useState<any>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (structuredLogs?.event_logs) {
      const eventLogs = structuredLogs.event_logs;
      let llmEvents = eventLogs.llm_inference_event || [];
      let modelEvents = eventLogs.model_inference_event || [];
      let ttsEvents = eventLogs.text_to_speech_saved_event || [];

      llmEvents = removeDuplicateInferenceIds(llmEvents);
      modelEvents = removeDuplicateInferenceIds(modelEvents);

      setLlmCalls(llmEvents);
      setModelCalls(modelEvents);
      setTTSEvents(ttsEvents);

      const firstCall = llmEvents[0] || modelEvents[0] || ttsEvents[0] || null;
      if (firstCall) {
        setSelectedCall(firstCall);
      }
    }
  }, [structuredLogs]);

  useEffect(() => {
    // Focus the modal container so it can catch ctrl+f
    if (showLogsModal && modalRef.current) {
      modalRef.current.focus();
    }
  }, [showLogsModal]);

  function removeDuplicateInferenceIds(events: any[]) {
    const uniqueEvents = new Map();
    for (const event of events) {
      const inferenceId =
        event.llm_inference_event?.inference_id ||
        event.model_inference_event?.inference_id ||
        event?.text_to_speech_saved_event?.inference_id;
      if (inferenceId) {
        if (
          !uniqueEvents.has(inferenceId) ||
          event.llm_inference_event?.is_shadow
        ) {
          uniqueEvents.set(inferenceId, event);
        }
      } else {
        uniqueEvents.set(Math.random(), event);
      }
    }
    return Array.from(uniqueEvents.values());
  }

  const contextId = message.id ?? message.chat_message_id;
  const contextMessages = getContextMessages(
    contextId,
    message.chat_session_id
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // If ctrl/cmd+f is pressed inside the modal,
    // we stop propagation so the global listener won't run.
    // We do NOT preventDefault() so that the browser's find appears.
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.stopPropagation();
    }
  };

  return (
    <Modal open={showLogsModal} onClose={() => setShowLogsModal(false)}>
      <Box
        ref={modalRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80vh",
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          outline: "none", // remove default focus outline if needed
        }}
      >
        {/* Top bar */}
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Typography variant="h4">Message Events</Typography>
          <IconButton onClick={() => setShowLogsModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        {loadingStructuredLogs ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>
              Loading this message's events...
            </Typography>
          </Box>
        ) : !structuredLogs || !structuredLogs.event_logs ? (
          <Box sx={{ p: 2 }}>
            <Typography>No logs available.</Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
            {/* Left panel: LLM and Model Calls with watchers */}
            <Box
              sx={{
                width: "30%",
                borderRight: "1px solid #ccc",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Box sx={{ flex: "1 1 auto", overflowY: "auto", p: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  LLM Inference Calls
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <List dense>
                  {llmCalls.map((call, i) => {
                    const llmEvent = call.llm_inference_event;
                    const name = llmEvent?.model_name || "Unknown Model";
                    const selected = selectedCall === call;
                    const watcherName = call.context?.watcher?.name
                      ? `Watcher: ${call.context.watcher.name}`
                      : "";

                    return (
                      <ListItem
                        key={i}
                        button
                        selected={selected}
                        onClick={() => setSelectedCall(call)}
                      >
                        <ListItemText primary={name} secondary={watcherName} />
                      </ListItem>
                    );
                  })}
                </List>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Model Inference Calls
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <List dense>
                  {modelCalls.map((call, i) => {
                    const modelEvent = call.model_inference_event;
                    const name = modelEvent?.model_key || "Unknown Model";
                    const selected = selectedCall === call;
                    const watcherName = call.context?.watcher?.name
                      ? `Watcher: ${call.context.watcher.name}`
                      : "";

                    return (
                      <ListItem
                        key={i}
                        button
                        selected={selected}
                        onClick={() => setSelectedCall(call)}
                      >
                        <ListItemText primary={name} secondary={watcherName} />
                      </ListItem>
                    );
                  })}
                </List>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Text to Speech Saved Events
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <List dense>
                  {TTSEvents.map((call, i) => {
                    const ttsEvent = call.text_to_speech_saved_event;
                    const name = ttsEvent?.blob_path || "Unknown Event";
                    const selected = selectedCall === call;

                    return (
                      <ListItem
                        key={i}
                        button
                        selected={selected}
                        onClick={() => setSelectedCall(call)}
                      >
                        <ListItemText primary={name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>

            {/* Right panel: Context + Call Details */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                p: 2,
              }}
            >
              {selectedCall ? (
                <>
                  {contextMessages.length > 0 && (
                    <ContextMessages
                      messages={contextMessages}
                      highlightMessageId={contextId}
                      getSpeakerName={getSpeakerName}
                    />
                  )}

                  <Typography variant="h5" sx={{ mb: 1, flexShrink: 0 }}>
                    Call Details
                  </Typography>

                  <Box
                    sx={{
                      flex: 1,
                      overflowY: "auto",
                      borderTop: "1px solid #ccc",
                      mt: 1,
                      pt: 1,
                    }}
                  >
                    <StructuredLogsViewer call={selectedCall} />
                  </Box>
                </>
              ) : (
                <Typography variant="body1">
                  Select a call to view details.
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default StructuredLogsModal;
