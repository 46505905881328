// conversationReducer.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchConversations,
  fetchChatSessions,
  fetchAnonymizedChatSessions,
  fetchTranscripts,
  fetchComments,
  addComment,
  editComment,
  deleteComment,
  setConversationShadowBanned,
  fetchStructuredLogs,
  generateChatSessionAudio,
  fetchMemoryData,
} from "../actions/conversationsActions";
import { UserName } from "./userReducer";
import {
  ChatAnnotation,
  ChatMessageModality,
} from "../../components/dashboard/conversations/message-bubble/MessageBubbleTypes";
import { getTargetId } from "../../utils/render";

export interface Conversation {
  id: string;
  human: any;
  bot: any;
  config?: any;
  sms_active?: boolean;
  route_to_debug?: boolean;
  introduced_by?: any;
  queue_offset?: number;
  sent_cursor?: any;
  created_at: string;
  version_major?: number;
  version_minor?: number;
  owning_user_id?: string;
  is_private?: boolean;
  environment?: string;
  latest_chat_message_time?: string;
  shadow_banned?: boolean;
}

export interface Transcript {
  conversation_id: string;
  created_at: string;
  environment: string;
  human_initial: string;
  bot_name: string;
  latest_chat_message_time?: string;
  num_turns_in_latest_session: number;
  is_private?: boolean;
}

interface SessionAudioMap {
  [chatSessionId: string]: {
    audioUrl?: string;
    loading?: boolean;
    error?: string;
  };
}

export interface ChatSession {
  id: string;
  start_time: string;
  end_time: string;
  messages: any[];
}

export interface Message {
  responding_to?: string;
  triggered_by_action_id?: string;
  client_msg_uid: string;
  id: number;
  chat_message_id: number;
  chat_session_id: string;
  created_at: string;
  type: "human" | "bot";
  content: string;
  annotations?: ChatAnnotation[];
  media_ref?: {
    blob_path: string;
    content_type: string;
  };
  modality?: ChatMessageModality;
  content_type?: string;
  stored_audio?: {
    path: string;
    container: string; // e.g., "audio-vad"
  };
  has_stored_audio?: boolean;
  percent_spoken?: number;
  chat_speaker_id: string;
}

export interface AnnotationUser {
  id: string;
  name: UserName;
}

export interface Comment {
  id: string;
  conversation_id: string;
  target_id?: string;
  user: AnnotationUser;
  comment: string;
  thread_id: string;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
  tags?: string[];
}

interface ConversationState {
  conversations: Conversation[];
  transcripts: Transcript[];
  anonymizedChatSessions: ChatSession[];
  chatSessions: ChatSession[];
  comments: Comment[];
  loading: boolean;
  loadingTranscript: boolean;
  loadingConversation: boolean;
  loadingComments: boolean;
  error: string | null;
  structuredLogs: { [key: string]: any };
  loadingStructuredLogs: { [key: string]: boolean };
  lastFetchTime: number;
  lastTranscriptFetchTime: number;
  sessionAudio: SessionAudioMap;
  memoryData: { [key: string]: any };
  loadingMemoryData: { [key: string]: boolean };
}

const initialState: ConversationState = {
  conversations: [],
  transcripts: [],
  chatSessions: [],
  anonymizedChatSessions: [],
  comments: [],
  loading: false,
  loadingTranscript: false,
  loadingConversation: false,
  loadingComments: false,
  error: null,
  structuredLogs: {},
  loadingStructuredLogs: {},
  lastFetchTime: 0,
  lastTranscriptFetchTime: 0,
  sessionAudio: {},
  memoryData: {},
  loadingMemoryData: {},
};

const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // structured log modal actions
      .addCase(fetchStructuredLogs.pending, (state, action) => {
        const { startMsgId, chatSessionId } = action.meta.arg;
        const targetId = getTargetId(startMsgId, chatSessionId);
        state.loadingStructuredLogs[targetId] = true;
        state.error = null;
      })
      .addCase(fetchStructuredLogs.fulfilled, (state, action) => {
        const { data, targetId } = action.payload;
        state.loadingStructuredLogs[targetId] = false;
        state.structuredLogs[targetId] = data;
      })
      .addCase(fetchStructuredLogs.rejected, (state, action) => {
        const { startMsgId, chatSessionId } = action.meta.arg;
        const targetId = getTargetId(startMsgId, chatSessionId);
        state.loadingStructuredLogs[targetId] = false;
        state.error =
          action.error.message || "Failed to fetch structured logs.";
      })
      // Handle fetchConversations action
      .addCase(fetchConversations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.conversations = action.payload.data;
        if (!action.payload.fromCache) {
          state.lastFetchTime = Date.now();
        }
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch conversations.";
      })
      // Handle fetchChatSessions action
      .addCase(fetchChatSessions.pending, (state) => {
        state.loadingConversation = true;
        state.error = null;
        state.chatSessions = [];
      })
      .addCase(
        fetchChatSessions.fulfilled,
        (state, action: PayloadAction<ChatSession[]>) => {
          state.loadingConversation = false;
          state.chatSessions = action.payload;
        }
      )
      .addCase(fetchChatSessions.rejected, (state, action) => {
        state.loadingConversation = false;
        state.error = action.error.message || "Failed to fetch chat sessions.";
      })
      // Handle fetchAnonymizedChatSessions action
      .addCase(fetchAnonymizedChatSessions.pending, (state) => {
        state.loadingTranscript = true;
        state.error = null;
        state.anonymizedChatSessions = [];
      })
      .addCase(
        fetchAnonymizedChatSessions.fulfilled,
        (state, action: PayloadAction<ChatSession[]>) => {
          state.loadingTranscript = false;
          state.anonymizedChatSessions = action.payload;
        }
      )
      .addCase(fetchAnonymizedChatSessions.rejected, (state, action) => {
        state.loadingTranscript = false;
        state.error =
          action.error.message || "Failed to fetch anonymized chat sessions.";
      })
      // Handle fetchTranscripts action
      .addCase(fetchTranscripts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTranscripts.fulfilled, (state, action) => {
        state.loading = false;
        state.transcripts = action.payload.data;
        if (!action.payload.fromCache) {
          state.lastTranscriptFetchTime = Date.now();
        }
      })
      .addCase(fetchTranscripts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch transcripts.";
      })
      // Handle fetchComments action
      .addCase(fetchComments.pending, (state) => {
        state.loadingComments = true;
        state.error = null;
        state.comments = [];
      })
      .addCase(
        fetchComments.fulfilled,
        (state, action: PayloadAction<Comment[]>) => {
          state.loadingComments = false;
          state.comments = action.payload;
        }
      )
      .addCase(fetchComments.rejected, (state, action) => {
        state.loadingComments = false;
        state.error = action.error.message || "Failed to fetch comments.";
      })
      // Handle addComment action
      .addCase(
        addComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          state.comments.push(action.payload);
        }
      )
      // Handle editComment action
      .addCase(
        editComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          const index = state.comments.findIndex(
            (comment) => comment.id === action.payload.id
          );
          if (index !== -1) {
            state.comments[index] = action.payload;
          }
        }
      )
      .addCase(generateChatSessionAudio.pending, (state, action) => {
        const { chatSessionId } = action.meta.arg;
        state.sessionAudio[chatSessionId] = {
          loading: true,
          error: undefined,
        };
      })
      .addCase(generateChatSessionAudio.fulfilled, (state, action) => {
        const { chatSessionId, audioUrl } = action.payload;
        state.sessionAudio[chatSessionId] = {
          loading: false,
          audioUrl: audioUrl,
        };
      })
      .addCase(generateChatSessionAudio.rejected, (state, action) => {
        const { chatSessionId } = action.meta.arg;
        state.sessionAudio[chatSessionId] = {
          loading: false,
          error: action.error.message || "Failed to generate audio",
        };
      })

      // Handle fetchMemoryData action

      .addCase(fetchMemoryData.pending, (state, action) => {
        const conversationId = action.meta.arg;
        state.loadingMemoryData[conversationId] = true;
        state.error = null;
      })

      .addCase(fetchMemoryData.fulfilled, (state, action) => {
        const conversationId = action.meta.arg;
        state.loadingMemoryData[conversationId] = false;
        state.memoryData[conversationId] = action.payload;
      })

      .addCase(fetchMemoryData.rejected, (state, action) => {
        const conversationId = action.meta.arg;
        state.loadingMemoryData[conversationId] = false;
        state.error = action.error.message || "Failed to fetch memory data.";
      })
      // Handle deleteComment action
      .addCase(
        deleteComment.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.comments = state.comments.filter(
            (comment) => comment.id !== action.payload
          );
        }
      )
      // Handle setConversationShadowBanned action
      .addCase(
        setConversationShadowBanned.fulfilled,
        (
          state,
          action: PayloadAction<{
            conversationId: string;
            newValue: boolean;
          }>
        ) => {
          const index = state.conversations.findIndex(
            (convo) => convo.id === action.payload.conversationId
          );
          if (index !== -1) {
            state.conversations[index].shadow_banned = action.payload.newValue;
          }
        }
      );
  },
});

export default conversationSlice.reducer;
