// SessionAudio.tsx
import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { generateChatSessionAudio } from "../../../../redux/actions/conversationsActions";
import AudioPlayer from "./AudioPlayer";

interface SessionAudioProps {
  conversationId: string;
  chatSessionId: string;
}

const SessionAudio: React.FC<SessionAudioProps> = ({
  conversationId,
  chatSessionId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const sessionAudio = useSelector(
    (state: RootState) => state.conversations.sessionAudio?.[chatSessionId]
  );

  const handleGenerateAudio = () => {
    dispatch(generateChatSessionAudio({ conversationId, chatSessionId }));
  };

  if (sessionAudio?.loading) {
    return (
      <Box display="flex" alignItems="center">
        <CircularProgress size={24} />
        <Typography ml={2}>Generating audio transcript...</Typography>
      </Box>
    );
  }

  if (sessionAudio?.error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography color="error">{sessionAudio.error}</Typography>
        <Button variant="outlined" onClick={handleGenerateAudio}>
          Retry
        </Button>
      </Box>
    );
  }

  if (sessionAudio?.audioUrl) {
    // Audio is ready
    return (
      <Box>
        <AudioPlayer
          conversationId={conversationId}
          storedAudio={{ path: "", container: "" }}
          externalAudioUrl={sessionAudio.audioUrl}
        />
      </Box>
    );
  }

  // If not loading, no error, and no audioUrl, show generate button
  return (
    <Button variant="outlined" onClick={handleGenerateAudio}>
      Generate Audio Transcript
    </Button>
  );
};

export default SessionAudio;
